h1 button {
    margin-left: 1em;
}

a {
    text-decoration: none;
}

body .w-md-editor-text-pre > code,
body .w-md-editor-text-input {
    font-family: monospace !important;
}
